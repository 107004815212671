import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as bannerApi from "../data/remote/banner";
import { constants as c } from "../constants";
const initialState = {
  listBanners: {
    data: [],
    status: c.NONE,
  },
};

const bannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    resetListBannerStatus: (state) => {
      return {
        ...state,
        listBanners: {
          data: [],
          status: c.NONE,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBanners.fulfilled, (state, action) => {
        return {
          ...state,
          listBanners: {
            data: action.payload,
            status: c.SUCCESS,
          },
        };
      })
      .addCase(getBanners.rejected, (state, action) => {
        return {
          ...state,
          listBanners: {
            data: action.payload,
            status: c.FAILUER,
          },
        };
      });
  },
});

export const getBanners = createAsyncThunk("banner/getBanners", async () => {
  const fetchBanners = async () => {
    const res = await bannerApi.getAllBanner();
    return res.data?.data;
  };
  return fetchBanners();
});

export const deleteBanner = createAsyncThunk(
  "banner/deleteBanner",
  async (id) => {
    const deleteBanner = async () => {
      const res = await bannerApi.deleteBanner(id);
      return res.data?.data;
    };
    return deleteBanner();
  }
);

export const listBanners = (state) => state.banners.listBanners;
export const { resetListBannerStatus } = bannerSlice.actions;

export default bannerSlice;
