import { PictureInPictureAltRounded } from "@material-ui/icons";
import React, { useState } from "react";
import { Navbar } from 'rsuite';
import { Nav } from 'rsuite';
import ArrowRightIcon from '@rsuite/icons/ArrowRight';

function logout() {
  window.localStorage.clear();
  window.location.href = "/login";
}

export default function Header(props) {

  return (
    <Navbar appearance="inverse" style={{
      backgroundColor: "#a53e3e"
    }} {...props}>
      <Navbar.Brand href="#">RSUITE</Navbar.Brand>

      <Nav pullRight>
        <Nav.Item onClick={() => {
          logout()
        }} icon={<ArrowRightIcon />}>Logout</Nav.Item>
      </Nav>
    </Navbar>
  );
}
