import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUser , fetchUserId } from "../data/remote/user";
import { constants as c } from "../constants";
const initialState = {
  info: {
    status: c.NONE,
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetListUserInfoStatus: (state) => {
      return {
        ...state,
        info: {
          status: c.NONE,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      return {
        ...state,
        info: {
          ...action.payload,
          status : c.SUCCESS
        }
      };
    });
  },
});

export const getUserInfo = createAsyncThunk("users/getUserInfo", async () => {
  const fetchUsers = async () => {
    const res = await fetchUserId();
    return res.data?.data;
  };
  return fetchUsers();
});

export const userInfo = (state) => state.profile.info;
export const { resetListUserInfoStatus } = profileSlice.actions;

export default profileSlice;
