import callApi from '../../utils/apiCaller';

export const getAllOnsale = (query) => {
  return callApi(`/user_advices${query}`, 'get', null);
};
export const deleteOnsale = (id) => {
  return callApi(`/user_advices/${id}`, 'delete', null);
};
export const postOnsale = (info) => {
  return callApi(`/user_advices`, 'post', info);
};
export const updateOnsale = (id , info) => {
  return callApi(`/user_advices/${id}`, 'put', info);
};


