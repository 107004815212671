import { callApi } from "../Interceptor";
export const usersAdviceData = {
  getListUserAdvice: (
    page,
    search,
    status,
    begin_date_register,
    end_date_register,
    begin_updated,
    end_updated,
    limit
  ) =>
    callApi(
      "admin/user_advices" +
        `?page=${page == null ? "" : page}` +
        `&status=${status == null ? "" : status}` +
        `&search=${search == null ? "" : search}` +
        `&begin_date_register=${
          begin_date_register == null ? "" : begin_date_register
        }` +
        `&end_date_register=${
          end_date_register == null ? "" : end_date_register
        }` +
        `&begin_updated=${begin_updated == null ? "" : begin_updated}` +
        `&end_updated=${end_updated == null ? "" : end_updated}` +
        `&limit=${limit == null ? "" : limit}`,
      "GET"
    ),
  updateDataUserAdvice: (id, data) =>
    callApi(`admin/user_advices/${id}`, "PUT", data),
  updateAllUserAdvice: (data) => callApi(`admin/user_advices`, "PUT", data),
  importExcel: (data) => callApi("admin/user_advices/all", "POST", data),
  createAdvices: (id, data) =>
    callApi(`admin/history_user_advices/${id}`, "POST", data),
};
