export const dataStatus = [
    {
        status: 0,
        name: "Cần tư vấn",
        color: 'orange'
    },
    {
        status: 1,
        name: "cold",
        color: 'grey'
    },
    {
        status: 5,
        name: "warm",
        color: '#2E86C1'
    },
    {
        status: 6,
        name: "hot",
        color: 'purple'
    },
    {
        status: 7,
        name: "non",
        color: 'blue'
    },
    {
        status: 2,
        name: "Đã chốt",
        color: "green"
    },
    {
        status: 3,
        name: "Bỏ tư vấn",
        color: 'red'
    },
    {
        status: 4,
        name: "Pos Free",
        color: '#996633'
    },
]