import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as demoApi  from "../data/remote/demo";
import { constants as c } from "../constants";
const initialState = {
  shop_demo: {
    status: c.NONE,
    data : []
  },
};

const demoSlice = createSlice({
  name: "demo",
  initialState,
  reducers: {
    resetListShopDemoStatus: (state) => {
      return {
        ...state,
        shop_demo: {
          status: c.NONE,
          data : []
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllShopDemo.fulfilled, (state, action) => {
      return {
        ...state,
        shop_demo: {
          data : action.payload,
          status : c.SUCCESS
        }
      };
    });
  },
});

export const getAllShopDemo = createAsyncThunk("users/getAllShopDemo", async () => {
  const fetchShopDemo = async () => {
    const res = await demoApi.getAllShopDemo();
    return res.data?.data;
  };
  return fetchShopDemo();
});

export const listShopDemo = (state) => state.demo.shop_demo;
export const { resetListShopDemoStatus } = demoSlice.actions;

export default demoSlice;
