import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllUser } from '../../data/remote/store';

const userSlice = createSlice({
  name: 'users',
  initialState: { status: 'idle', users: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.status = 'idle';
      state.users = action.payload;
    });
  },
});

export const getUser = createAsyncThunk('users/getUser', async () => {
  const fetchUsers = async () => {
    const res = await getAllUser();
    return res.data?.data.data;
  };
  return fetchUsers();
});

export default userSlice;
