import callApi from '../../utils/apiCaller';

export const getAllShopDemo = () => {
  return callApi('/setup_data_example', 'get', null);
};
export const deleteBanner = (id) => {
  return callApi(`/banners/${id}`, 'delete', null);
};
export const postBanner = (info) => {
  return callApi(`/banners`, 'post', info);
};
export const updateShopDemo = (info) => {
  return callApi(`/setup_data_example`, 'put', info);
};


