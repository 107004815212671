import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Button, Modal, Row } from 'rsuite';
import RemindOutlineIcon from '@rsuite/icons/RemindOutline';
// import { toast } from 'react-toastify';
import toast from 'siiimple-toast';
import 'siiimple-toast/dist/style.css';// style required

export  function sagaDialogSuccess(mess) {
    toast.success(mess);
    // toast.success(mess, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    // });
}


export  function sagaDialogError(mess) {
    toast.alert(mess);
    // toast.error(mess, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    // });
}


export  function sagaDialogYesNo(mess, onYes, onNo) {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <Modal role="alertdialog" size="xs" open={true} onClose={onClose}>
                    <Modal.Header>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <RemindOutlineIcon
                            style={{
                                color: '#ffb300',
                                fontSize: 24
                            }}
                        />
                        {mess}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => {

                            if (onYes != null) onYes()
                            onClose();
                        }} appearance="primary">
                            Đồng ý
                        </Button>
                        <Button onClick={() => {
                            if (onNo != null) onNo()
                            onClose();
                        }} appearance="subtle">
                            Hủy
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        },
        title: mess,
        message: '',
        buttons: [
            {
                label: 'Yes',
                onClick: () => onYes()
            },
            {
                label: 'No',
                onClick: () => onNo()
            }
        ]
    });
};
