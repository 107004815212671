// import "./App.css";
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';

const Dashboard = React.lazy(() => import('./pages/Dashboard/DashboardPage'));
const StorePage = React.lazy(() => import('./pages/Store'));
const StaffPage = React.lazy(() => import('./pages/Staff'));
const UserPage = React.lazy(() => import('./pages/User'));
const BannerPage = React.lazy(() => import('./pages/Banner'));
const ProfilePage = React.lazy(() => import('./pages/Profile'));
const OnsalePage = React.lazy(() => import('./pages/Onsale'));
const ShopDemoPage = React.lazy(() => import('./pages/ShopDemo'));
const LoginPage = React.lazy(() => import('./pages/login/Login'));
const HistoryOperation = React.lazy(() => import('./pages/HistoryOperation/StorePage'));


const OtpPage = React.lazy(() => import('./pages/Otp'));


function App(props) {
  const location = window.location.pathname;
  const [accept, setAccept] = useState(false);

  const isLoginPage = [
    '/login',
    '/register',
    '/forgot_password/otp',
    '/forgot_password',
  ].includes(location);

  useEffect(() => {
    try {
      const token = localStorage.getItem('admin-token');
      if (isLoginPage == false) {
        if (!token) window.location.href = '/login';
        else setAccept(true);
      } else {
        if (token) {
          window.location.href = '/';
          setAccept(true);
        }
      }
    } catch (error) {
      if (isLoginPage == false) {
        window.location.href = '/login';
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <React.Fragment>
        <Box>
          {isLoginPage == false && accept == true && (
            <Grid item xs={12}>
              <Header location={props.location} />
            </Grid>
          )}
          <Box display={'flex'} marginTop={'0px'} marginLeft={'20px'}>
            {isLoginPage == false && accept == true && (
              <Sidebar location={props.location} />
            )}
            <Box width={'100%'}>
              <React.Suspense fallback={<p></p>}>
                <Switch>
                  <Route path="/login" exact component={LoginPage} />

                  {isLoginPage == false && accept == true && (
                    <>
                      <Route path="/" exact component={Dashboard} />
                      <Route path="/stores" exact component={StorePage} />
                      <Route path="/staffs" exact component={StaffPage} />
                      <Route path="/users" exact component={UserPage} />
                      <Route path="/history_operation" exact component={HistoryOperation} />
                      <Route path="/onsales" exact component={OnsalePage} />
                      <Route path="/otps" exact component={OtpPage} />
                      <Route path="/banners" exact component={BannerPage} />
                      <Route path="/profile" exact component={ProfilePage} />
                      <Route
                        path="/shops_demo"
                        exact
                        component={ShopDemoPage}
                      />
                    </>
                  )}
                </Switch>
              </React.Suspense>{' '}
            </Box>
          </Box>
        </Box>
      </React.Fragment>

      <ToastContainer />
    </BrowserRouter>
  );
}
export default App;
