import axios from 'axios';
// import * as Types from '../constants/ActionType';
import * as userLocalApi from './local/user';
import history from '../history';
import { getLinkApi } from '../constants/config';
import { useNavigate } from 'react-router-dom';
const exceptPrefix = ['/login', '/register', '/otp', '/forgot'];
const POST = 'POST';
const GET = 'GET';
const PUT = 'PUT';
const DELETE = 'DELETE';
function checkIsAuth(endpoint) {
  for (const prefix of exceptPrefix) {
    if (endpoint.includes(prefix) == true) {
      return true;
    }
  }
  return false;
}
export default function callApi2(endpoint, method, body) {
  // const res = await axios({
  //     method: method,
  //     url: `${getLinkApi()}${endpoint}`,
  //     data: body,
  // });
  return axios({
    method: method,
    url: `${getLinkApi()}${endpoint}`,
    data: body,
  });
}
export const callApi = async (endpoint, method, body) => {
  if (checkIsAuth(endpoint) == false) {
    axios.interceptors.request.use(
      (config) => {
        config.headers = {
          ...config.headers,
          ['admin-token']: userLocalApi.getToken(),
        };
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (
          typeof error.response?.data?.code !== 'undefined' &&
          error.response?.data?.code === 404
        ) {
          history.push('/notfound');
        } else if (
          typeof error.response?.data?.code !== 'undefined' &&
          error.response?.data?.code === 401
        ) {
          window.location.href = '/login';
        } else {
          // store.dispatch({
          //   type: Types.LOGIN_SUCCESS,
          //   auth: true,
          // });
        }
        try {
          if (error.response.data.code == 404) {
            // store.dispatch({
            //   type: Types.ERROR_RESPONSE,
            //   alert: {
            //     type: 'danger',
            //     title: 'Lỗi ',
            //     disable: 'show',
            //     content: 'Đã xảy ra lỗi, vui lòng kiểm tra lại',
            //   },
            // });
          }
          // store.dispatch({
          //   type: Types.SHOW_LOADING,
          //   loading: 'hide',
          // });
        } catch (error) {
          // store.dispatch({
          //   type: Types.SHOW_LOADING,
          //   loading: 'hide',
          // });
        }
        return error;
      }
    );
  } else {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        return error;
      }
    );
  }
  try {
    const response = await axios({
      method: method,
      url: `${getLinkApi()}${endpoint}`,
      data: body,
    });
    //  console.log(response)
    if (response.status != 200) {
      if (response.status === 401) {
        window.location.href = '/login';
      }
      //console.log(response)
      return Promise.reject(response.response.data.msg);
    } else {
      return response.data?.data ?? {};
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err.data.msg);
  }
};
