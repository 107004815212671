export const getToken = () => {
  return localStorage.getItem('admin-token');
};
export const getUserId = () => {
  return localStorage.getItem('userId');
};
export const setToken = (token) => {
  localStorage.setItem('admin-token', token);
};
export const setUserId = (userId) => {
  localStorage.setItem('userId', userId);
};

export const removeToken = () => {
  localStorage.clear();
};
