import React, { useState, useEffect } from 'react';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Inbox as InboxIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { getStore } from '../../../../pages/Store/storeSlice';
import { getUser } from '../../../../pages/User/userSlice';

import { useDispatch } from 'react-redux';

// styles
import useStyles from './styles';

// components
import Dot from '../Dot';
import { dataStatus } from '../../../../pages/Onsale/components/data';


const getDataStatusByNum = (num) => {
  var ob = dataStatus.find((ele) => ele.status == num) ?? {}
  return ob
}


export default function SidebarLink({
  link,
  icon,
  label,
  children,
  children2,
  location,
  isSidebarOpened,
  nested,
  type,
}) {
  var classes = useStyles();
  const dispatch = useDispatch();

  // local
  var [isOpen, setIsOpen] = useState(false);
  var isLinkActive =
    link &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1);

  if (type === 'title')
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );

  if (type === 'divider') return <Divider className={classes.divider} />;
  if (link && link.includes('http')) {
    return (
      <ListItem
        button
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <a className={classes.externalLink} href={link}>
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {nested ? <Dot color={isLinkActive && 'primary'} /> : icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={
              <Typography style={{ fontSize: '14px', color: '#6E6E6ECC' }}>
                {label}
              </Typography>
            }
          />
        </a>
      </ListItem>
    );
  }
  if (!children)
    return (
      <ListItem
        button
        component={link && Link}
        to={link}
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon
          style={{ minWidth: '0px' }}
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {nested ? <Dot color={isLinkActive && 'primary'} /> : icon}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={
            <>
              <Typography style={{ fontSize: '14px', color: '#6E6E6ECC' }}>
                {label}
              </Typography>
              {children2 != null &&
                <Typography style={{ fontSize: '14px', color: '#6E6E6ECC' }}>
                  {children2.map((item) =>
                    <Link to={"/onsales?status=" + item.status}>
                      <p style={{
                        background: getDataStatusByNum(item.status).color,
                        height: 30,
                        width:100,
                        padding: "5px",
                        borderRadius: "10px",
                        margin: "2px",
                        color: 'white'
                      }}>
                        {"(" + item.total + ") " + getDataStatusByNum(item.status).name}
                      </p>
                    </Link>
                  )}
                </Typography>
              }
            </>
          }
        />
      </ListItem>
    );

  // fecth data
  // if (link == 'stores') {
  //   useEffect(() => {
  //     dispatch(getStore());
  //   }, []);
  // } else if (link == 'users') {
  //   useEffect(() => {
  //     dispatch(getUser());
  //   }, []);
  // }

  // useEffect(() => {
  //   // if (link === 'stores') {
  //   //   dispatch(getUser());
  //   // } else if (link === 'users') {
  //   dispatch(getStore());
  //   // }
  // }, []);

  return (
    <>
      <ListItem
        button
        component={link && Link}
        onClick={toggleCollapse}
        className={classes.link}
        to={link}
        disableRipple
      >
        <ListItemIcon
          style={{ minWidth: '0px' }}
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {icon ? icon : <InboxIcon />}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography style={{ fontSize: '14px', color: '#6E6E6ECC' }}>
              {label}
            </Typography>
          }
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
        // primary={label}
        />
      </ListItem>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={classes.nestedList}
        >
          <List component="div" disablePadding>
            {children.map((childrenLink) => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                location={location}
                isSidebarOpened={isSidebarOpened}
                classes={classes}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
