import callApi from '../../utils/apiCaller';

export const getAllStore = (params = [], page = 1) => {
  let url = `/manage/stores?page=${page}`;

  return callApi(url, 'get', null);
};

export const reqUpdateStore = (store) => {
  return callApi(`/manage/stores/${store.id}`, 'put', store);
};

export const getAllUser = () => {
  return callApi('/manage/users?page=1', 'get', null);
};
