import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as onsaleApi from '../data/remote/onsale';
import { constants as c } from '../constants';
const initialState = {
  listOnsales: {
    data: [],
    status : c.NONE
  },

};

const onsaleSlice =  createSlice({
  name: 'onsales',
  initialState,
  reducers: {
    resetListOnsaleStatus: (state) => {
      return {
        ...state,
        listOnsales: {
          data : [],
          status : c.NONE
        }
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOnsales.fulfilled, (state, action) => {
      return {
        ...state,
        listOnsales: {
          data : action.payload,
          status : c.SUCCESS
        }
      };
    })
    .addCase(getOnsales.rejected, (state, action) => {
      return {
        ...state,
        listOnsales: {
          data : action.payload,
          status : c.FAILUER
        }
      };
    })
    ;
    
  },
});

export const getOnsales = createAsyncThunk('onsale/getOnsales', async (query="") => {
  const fetchOnsales = async () => {
    const res = await onsaleApi.getAllOnsale(query);
    return res.data?.data;
  };
  return fetchOnsales();
});

export const deleteOnsale = createAsyncThunk('onsale/deleteOnsale', async (id) => {
  const deleteOnsale = async () => {
    const res = await onsaleApi.deleteOnsale(id);
    return res.data?.data;
  };
  return deleteOnsale();
});





export const listOnsales = (state) => state.onsales.listOnsales;
export const { resetListOnsaleStatus } = onsaleSlice.actions;

export default onsaleSlice;


