import { callApi } from '../Interceptor';
export const usersData = {
  getListUser: (page, search, begin_date_register, end_date_register, begin_last_visit_time,end_last_visit_time,limit ) =>
    callApi(
      'admin/manage/users' +
        `?page=${page == null ? '' : page}` +
        `&search=${search == null ? '' : search}`+
        `&begin_date_register=${begin_date_register == null ? '' : begin_date_register}`+
        `&end_date_register=${end_date_register == null ? '' : end_date_register}`+
        `&begin_last_visit_time=${begin_last_visit_time == null ? '' : begin_last_visit_time}`+
        `&end_last_visit_time=${end_last_visit_time == null ? '' : end_last_visit_time}`, +
        `&limit=${limit == null ? '' : limit}`,

      'GET'
    ),
  updateUser: (user) => callApi(`admin/manage/users/` + user.id, 'PUT', user),
};
