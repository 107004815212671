import { callApi } from '../Interceptor';
export const employeesData = {
  getListEmployee: (page, search, begin_date_register, end_date_register, begin_last_visit_time, end_last_visit_time) =>
    callApi(
      'admin/employee' +
      `?page=${page == null ? '' : page}`,
      'GET'
    ),
  editEmployee: (employee) => callApi(`admin/employee/` + employee.id, 'PUT', employee),
  createmployee: (employee) => callApi(`admin/employee`, 'POST', employee),
  deleteEmployee: (employeeId) => callApi(`admin/employee/` + employeeId, 'DELETE'),
};
