import { callApi } from "../Interceptor";
export const storesData = {
  getListStores: (
    page,
    search,
    begin_date_expried,
    end_date_expried,
    type_compare_date_expried,
    begin_date_register,
    end_date_register,
    limit,
  ) =>
    callApi(
      "admin/manage/stores" +
        `?page=${page == null ? "" : page}` +
        `&search=${search == null ? "" : search}` +
        `&begin_date_expried=${
          begin_date_expried == null ? "" : begin_date_expried
        }` +
        `&end_date_expried=${
          end_date_expried == null ? "" : end_date_expried
        }` +
        `&begin_date_register=${
          begin_date_register == null ? "" : begin_date_register
        }` +
        `&end_date_register=${
          end_date_register == null ? "" : end_date_register
        }` +
        `&type_compare_date_expried=${
          type_compare_date_expried == null ? "" : type_compare_date_expried
        }`+
        `&limit=${
          limit == null ? "" : limit
        }`,
      "GET"
    ),
  updateStore: (store) =>
    callApi(`admin/manage/stores/` + store.id, "PUT", store),
  getOneStore: (store_code) =>
    callApi(`admin/manage/stores_by_code/` + store_code, "GET"),
  getNotificationConfig: (store_code) =>
    callApi(`admin/notification/user/config?store_code=` + store_code, "GET"),
  updateNotificationConfigKey: (data) =>
    callApi(`admin/notification/user/config`, "POST", data),
  getHistoryOperation: (page,store_id) =>
    callApi(
      "admin/operation_histories" + `?store_id=${store_id == null ? "" : store_id}`
      + `&page=${page == null ? "" : page}`
      ,
      "GET"
    ),
};
