import React, { useState, useEffect } from 'react';
import App from './App';
import { sagaDialogError } from './components/dialog/dialog';
import { RepositoryManager } from './data2/repositoryManage';
import * as userLocalApi from './data/local/user';

export const MainContext = React.createContext({});

export default function MainContextData() {

    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState({})

    const getBadges = async () => {

        setLoading(true)
        try {
            var data = await RepositoryManager.badgesData.getBadges()
            setValue({
                ...value,
                badges: data
            })

        } catch (err) {
            sagaDialogError(err)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (userLocalApi.getToken() != null) {
            getBadges()
        }

    }, [userLocalApi.getToken()]);

    return (
        <MainContext.Provider value={value}>
            <App />
        </MainContext.Provider>
    )
}