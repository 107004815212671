import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import storeSlice from '../pages/Store/storeSlice';
import userSlice from '../pages/User/userSlice';
import bannerSlice from '../features/bannerSlice';
import profileSlice from '../features/profileSlice';
import demoSlice from '../features/demoSlice';
import onsalesSlice from '../features/onsaleSlice';

const store = configureStore({
  reducer: {
    stores: storeSlice.reducer,
    users: userSlice.reducer,
    banners: bannerSlice.reducer,
    profile: profileSlice.reducer,
    demo: demoSlice.reducer,
    onsales: onsalesSlice.reducer,
  },
});

export default store;
