import axios from 'axios';

import * as userLocalApi from '../data/local/user';
import { getLinkApi } from '../constants/config';

const exceptPrefix = ['/login', '/register', '/otp', '/forgot'];

function checkEndPoint(endpoint) {
  for (const prefix of exceptPrefix) {
    if (endpoint.includes(prefix) == true) {
      return true;
    }
  }
  return false;
}


export default   (endPoint, method, body) => {
  if (checkEndPoint(endPoint) === false) {
    axios.interceptors.request.use(
      (config) => {
        config.headers.common['admin-token'] = userLocalApi.getToken();
        return config;
      },
      (error) => {
        console.log(error,"xxx")
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
     
        if (error?.response?.data?.code === 404) {
          // window.location.replace("/khong-tim-thay-trang");
        } else if (error?.response?.data?.code === 401) {
          // removeToken();
          // history.push("/login")
        }
        return Promise.reject(error);
      }
    );
  }
  return axios({
    method,
    url: getLinkApi() +`admin${endPoint}`,
    data: body,
    headers: {
      "Content-Type": "application/json",
      // "device-id": `${c.STORE_CODE}-${uuid}`,
      // "device-id": `ikidemo-2750bc42-702e-4cbe-bae5-798f171389e1`,
    },
  });
};