import React, { useState, useEffect, useContext } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import HistoryIcon from "@rsuite/icons/History";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  AccountBalanceOutlined,
  PeopleAltOutlined,
  People,
  PeopleAltRounded,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Person2Icon from "@mui/icons-material/Person2";
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";
import AodIcon from "@mui/icons-material/Aod";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import { MainContext } from "../../MainContext";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import { dataStatus } from "../../pages/Onsale/components/data";

function Sidebar({ location }) {
  const mainData = useContext(MainContext);

  var structure = [];
  console.log(mainData);

  if (mainData?.badges?.id_decentralization == 1) {
    structure = [
      { id: 0, label: "Trang chủ", link: "/", icon: <HomeIcon /> },
      {
        id: 1,
        label: "Danh sách cửa hàng",
        link: "/stores",
        icon: <AccountBalanceOutlined />,
      },
      {
        id: 2,
        label: "Danh sách User",
        link: "/users",
        icon: <PeopleAltRounded />,
      },
      {
        id: 22,
        label: "Lịch sử thao tác KH",
        link: "/history_operation",
        icon: <HistoryIcon />,
      },

      {
        id: 4,
        label: "Danh sách KH cần tư vấn",
        link: "/onsales",
        icon: <PeopleAltOutlined />,
        children: [
          {
            label: `Tất cả(${mainData?.badges?.total_user_advice})`,
            link: "/onsales",
          },

          {
            label: `Cần tư vấn (${mainData?.badges?.total_user_advice_status_0})`,
            link: "/onsales?status=0",
          },
          {
            label: `Đang tư vấn (${mainData?.badges?.total_user_advice_status_all_consulting})`,
            link: "/onsales?status=-1",
            children2: [
              {
                status: 1,
                total: mainData?.badges?.total_user_advice_status_1 ?? 0,
              },
              {
                status: 5,
                total: mainData?.badges?.total_user_advice_status_5 ?? 0,
              },
              {
                status: 6,
                total: mainData?.badges?.total_user_advice_status_6 ?? 0,
              },
              {
                status: 7,
                total: mainData?.badges?.total_user_advice_status_7 ?? 0,
              },
            ],
          },
          {
            label: `Đã chốt (${mainData?.badges?.total_user_advice_status_2})`,
            link: "/onsales?status=2",
          },
          {
            label: `Đã hủy (${mainData?.badges?.total_user_advice_status_3})`,
            link: "/onsales?status=3",
          },
          {
            label: `Free pos (${mainData?.badges?.total_user_advice_status_4})`,
            link: "/onsales?status=4",
          },
        ],
      },
      {
        id: 5,
        label: "Lấy mã OTP",
        link: "/otps",
        icon: <AodIcon />,
      },

      {
        id: 7,
        label: "Tài khoản",
        link: "/profile",
        icon: <Person2Icon />,
      },
    ];
  } else if (mainData?.badges?.id_decentralization == 2) {
    structure = [
      { id: 0, label: "Trang chủ", link: "/", icon: <HomeIcon /> },

      {
        id: 4,
        label: "Danh sách KH cần tư vấn",
        link: "/onsales",
        icon: <PeopleAltOutlined />,
        children: [
          {
            label: `Tất cả(${mainData?.badges?.total_user_advice})`,
            link: "/onsales",
          },

          {
            label: `Cần tư vấn (${mainData?.badges?.total_user_advice_status_0})`,
            link: "/onsales?status=0",
          },
          {
            label: `Đang tư vấn (${mainData?.badges?.total_user_advice_status_all_consulting})`,
            link: "/onsales?status=-1",
            children2: [
              {
                status: 1,
                total: mainData?.badges?.total_user_advice_status_1 ?? 0,
              },
              {
                status: 5,
                total: mainData?.badges?.total_user_advice_status_5 ?? 0,
              },
              {
                status: 6,
                total: mainData?.badges?.total_user_advice_status_6 ?? 0,
              },
              {
                status: 7,
                total: mainData?.badges?.total_user_advice_status_7 ?? 0,
              },
            ],
          },
          {
            label: `Đã chốt (${mainData?.badges?.total_user_advice_status_2})`,
            link: "/onsales?status=2",
          },
          {
            label: `Đã hủy (${mainData?.badges?.total_user_advice_status_3})`,
            link: "/onsales?status=3",
          },
          {
            label: `Free pos (${mainData?.badges?.total_user_advice_status_4})`,
            link: "/onsales?status=4",
          },
        ],
      },
    ];
  } else {
    structure = [
      { id: 0, label: "Trang chủ", link: "/", icon: <HomeIcon /> },
      {
        id: 1,
        label: "Danh sách cửa hàng",
        link: "/stores",
        icon: <AccountBalanceOutlined />,
      },
      {
        id: 2,
        label: "Danh sách User",
        link: "/users",
        icon: <PeopleAltRounded />,
      },
      {
        id: 22,
        label: "Lịch sử thao tác KH",
        link: "/history_operation",
        icon: <HistoryIcon />,
      },
      {
        id: 3,
        label: "Quản lý nhân viên",
        link: "/staffs",
        icon: <People />,
      },
      {
        id: 4,
        label: "Danh sách KH cần tư vấn",
        link: "/onsales",
        icon: <PeopleAltOutlined />,
        children: [
          {
            label: `Tất cả(${mainData?.badges?.total_user_advice})`,
            link: "/onsales",
          },

          {
            label: `Cần tư vấn (${mainData?.badges?.total_user_advice_status_0})`,
            link: "/onsales?status=0",
          },
          {
            label: `Đang tư vấn (${mainData?.badges?.total_user_advice_status_all_consulting})`,
            link: "/onsales?status=-1",
            children2: [
              {
                status: 1,
                total: mainData?.badges?.total_user_advice_status_1 ?? 0,
              },
              {
                status: 5,
                total: mainData?.badges?.total_user_advice_status_5 ?? 0,
              },
              {
                status: 6,
                total: mainData?.badges?.total_user_advice_status_6 ?? 0,
              },
              {
                status: 7,
                total: mainData?.badges?.total_user_advice_status_7 ?? 0,
              },
            ],
          },
          {
            label: `Đã chốt (${mainData?.badges?.total_user_advice_status_2})`,
            link: "/onsales?status=2",
          },
          {
            label: `Đã hủy (${mainData?.badges?.total_user_advice_status_3})`,
            link: "/onsales?status=3",
          },
          {
            label: `Free pos (${mainData?.badges?.total_user_advice_status_4})`,
            link: "/onsales?status=4",
          },
        ],
      },
      {
        id: 5,
        label: "Lấy mã OTP",
        link: "/otps",
        icon: <AodIcon />,
      },
      {
        id: 6,
        label: "Quản lý Banner",
        link: "/banners",
        icon: <ClearAllIcon />,
      },
      {
        id: 7,
        label: "Tài khoản",
        link: "/profile",
        icon: <Person2Icon />,
      },
      {
        id: 8,
        label: "Dữ liệu Shop Demo",
        link: "/shops_demo",
        icon: <BubbleChartIcon />,
      },
    ];
  }

  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <img src="/assets/logo.png"></img>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            children={link.children}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
