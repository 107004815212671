import { usersData } from "./remote/user";
import { usersAdviceData } from "./remote/user_advice";
import { storesData } from "./remote/stores";
import { badgesData } from "./remote/badges";
import { employeesData } from "./remote/staff";
import { dashboardData } from "./remote/dashboard";
import { historyData } from "./remote/history";
export const RepositoryManager = {
  usersData,
  usersAdviceData,
  storesData,
  badgesData,
  employeesData,
  dashboardData,
  historyData,
};
