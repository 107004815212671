import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllStore, reqUpdateStore } from '../../data/remote/store';

const storeSlice = createSlice({
  name: 'stores',
  initialState: { status: 'idle', stores: [] },
  reducers: {
    toggleActiveStore: (state, action) => {
      const currentStore = state.stores.filter(
        (store) => store.id === action.payload.id
      );
      if (currentStore) {
        currentStore.has_upload_store = !currentStore.has_upload_store;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStore.fulfilled, (state, action) => {
      state.status = 'idle';
      state.stores = action.payload;
    });

    builder.addCase(updateStore.fulfilled, (state, action) => {
      state.status = 'idle';
      state.stores = state.stores.map((store) => {
        if (store.id === action.payload.id) {
          return action.payload;
        }
        return store;
      });
    });
  },
});

export const getStore = createAsyncThunk('stores/getStore', async (query) => {
  const fetchStores = async () => {
    const res = await getAllStore();
    return res.data?.data.data;
  };
  return fetchStores();
});

export const updateStore = createAsyncThunk(
  'stores/updateStore',
  async (store) => {
    const updateStore = async () => {
      const res = await reqUpdateStore(store);
      return res.data?.data;
    };

    return updateStore();
  }
);

export const { resetListStore } = storeSlice.actions;

export default storeSlice;
